.form-container{
    width: 521px;
    height: 274px;
    border-radius: 20px;
    padding: 20px;
}
.form-heading{
    font-size:16px;
    font-weight: 600;
    color: #505050;
}
.frenchise-bg-img{
    background-image: url('../../asets/frenchise.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: scroll;
    min-height: 380px; 
}
.frenchise-card{
    background-image: url('../../asets/1.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: scroll;
min-width:100%;
padding: 0px 12px;
border-radius:12px 0 0 0;
height:300px;

}
.pdi-card{
  min-width:100%;
}
.pdi-box{
  width: 400px;
}
.pdi-img{
  width: 100%;
  height: 200px;
}
.pdi-img img{
  width: 100%;
  height: auto;
}
/* workshop */
.map iframe{
  width: 100% !important;
}
/*all 3 banner */
.banner-caption{
  top: 34%;
  left: 20%;
  z-index: 8;
}
.banner-overlay{
   width:100%;
   height: 400px;
   border: 1p solid red;
   background-color: rgb(17 18 17 / 40%);
   z-index: 4;
   top: 0px;
    position: absolute;
   
}

.download-pdf{
    font-size: 24px;
    background-color: #b8242a;
    min-width: 240px;
    border-radius: 8px;
    border: none;
    padding: 13px 13px;


}
.download-pdf .pdf-icon{
    width: 48px;
    height: 32px;
    border-radius: 6px;
    background-color: white;
    
}
.download-pdf span{
  color: white;
  font-weight: 700;
}

@media(max-width:770px){
  .form-container{
    width: 100%;
    margin-bottom: 20px;
  } 
  .pdi-box{
    width:100%;
  }
  .red-custom-btn{
    display: block !important;
    width: 100% !important;
  } 
  .frenchise-card{
    height: 200px;
    border-radius: 20px !important;
  }
  .frenchise-card-innerbox{
    height: 130px;
    padding: 10px 20px;
    justify-content: space-between !important;
  }

  .banner-caption{
    top: 24%;
    left: 20%;
    z-index: 8;
  }
}