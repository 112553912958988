.personalaccrodian {
  background: linear-gradient(45deg, #ff0101, #990101) !important;
  color: #ffff !important;
  position: relative;
  box-shadow: none !important;
}
/* .container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
} */

.row {
  display: flex;
  justify-content: center;
}

/* .accordion-item {
  margin-bottom: 0.5rem;
} */

/* h1,
h4 {
  text-align: center;
} */

.img-fluid {
  max-height: 450px;
  object-fit: cover;
}

/* installation */
body {
  margin: 0 auto;
  font-family: "Hind", sans-serif;
}

.installation-process {
  height: auto;
  margin-bottom: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.installation-process h2 {
  text-transform: uppercase;
  font-size: 42px;
  text-align: center;
  color: #b8242a;
  font-weight: 700;
  margin-bottom: 40px;
}

.installation-process-step {
  display: inline-block;
  width: 23%;
  height: 225px;
  vertical-align: top;
  border-radius: 5px;
  margin: 0 10px;
  position: relative;
  border: 2px solid transparent;
  transition: all 0.3s ease-in-out;
  height: auto;
}

.installation-process-step:hover {
  transform: translateY(-10px);
}

.installation-process-step h3 {
  text-align: center;
  margin: 0;
  font-size: 21px;
  padding: 30px 0 8px 0;
  border-top-right-radius: 2px;
  border-top-left-radius: 2px;
  color: #fff;
}

.installation-process-step p {
  padding: 15px;
  font-size: 15px;
}

.purchase-step {
  border-color: #b8242a;
}

.purchase-step h3 {
  /* background-color: #b8242a; */
  background: linear-gradient(45deg, #ff0101, #990101) !important;
}

.call-scheduled-step {
  border-color: #b8242a;
}

.call-scheduled-step h3 {
  background: linear-gradient(45deg, #ff0101, #990101) !important;
}

.install-test-step {
  border-color: #b8242a;
}

.install-test-step h3 {
  background: linear-gradient(45deg, #ff0101, #990101) !important;
}

.finished-step {
  border-color: #b8242a;
}

.finished-step h3 {
  background: linear-gradient(45deg, #ff0101, #990101) !important;
}

.installation-process-step:before {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  width: 50px;
  height: 50px;
  border: 3px solid;
  border-radius: 50%;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 28px;
}

.purchase-step:before {
  content: "\f135";
  border-color: #b8242a;
  color: #b8242a;
}

.call-scheduled-step:before {
  content: "\f13d";
  border-color: #b8242a;
  color: #b8242a;
}

.install-test-step:before {
  content: "\f013";
  border-color: #b8242a;
  color: #b8242a;
}

.finished-step:before {
  content: "\f00c";
  border-color: #b8242a;
  color: #b8242a;
}

.installation-process-step + .installation-process-step:after {
  content: "\f0da";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  position: absolute;
  /* left: -25px;
   */
  font-size: 35px;
  top: 70px;
  color: #ff0101 !important;
  width: auto;
  object-fit: cover;
}
/* The PPF Installation Process */
html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif;
}

.col h3 {
  margin: 0;
  position: absolute;
  top: 40px;
  left: 10px;
}


.PPF-Installation {
  text-transform: uppercase;
  font-size: 42px;
  text-align: center;
  color: #b8242a;
  font-weight: 700;
  margin-bottom: 40px;
}

@media screen and (max-width: 600px) {
  .circle {
    display: none;
  }
}

.circle {
  width: 125px;
  height: 125px;
  border: 15px solid transparent;
  border-radius: 50%;
  margin: 0;
  position: relative;
}

.circle1 {
  border-color: #fd1d1d;
  border-left-color: transparent;
  /* border-top-color: transparent; */
}

.circle2 {
  border-color: #b8242a;
  border-right-color: transparent;
}

.circle3 {
  border-color: #fd1d1d;
  border-left-color: transparent;
}

.circle4 {
  border-color: #b8242a;
  border-right-color: transparent;
}

.circle h2 {
  display: table;
  margin: 5px 0 0 5px;
  width: 85px;
  height: 85px;
  background: #b8242a;
  border-radius: 50%;
}

.circle2 h2 {
  background: #b8242a;
}

.circle3 h2 {
  background: #b8242a;
}

.circle4 h2 {
  background: #b8242a;
}

.circle span {
  font-size: 11px;
  color: #fff;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.circle i,
.circle b {
  font-weight: bold;
  font-style: normal;
  display: block;
}

.circle i {
  font-size: 24px;
}

.wrap {
  display: flex;
  flex-wrap: wrap;
  max-width: 980px;
  margin: auto;
}

.col {
  flex: 1 0 50%;
  display: flex;
  align-items: center;
  margin-bottom: -47px;
  position: relative;
  left: 23px;
}

.col2 {
  left: -24px;
}

.col1 {
  align-content: flex-end;
}

.col1 .ppf-content {
  order: -1;
}

.ppf-content {
  flex: 1 0 0%;
  padding: 1px 10px;
  margin: -20px 0;
}

.ppf-content h2 {
  font-size: 1rem;
}

.parraTitle {
  font-size: 18px !important;
  color: #b8242a !important;
 
  font-weight: 550;
}

p {
  margin: 0.2em 0 0 0;
  font-size: 15px !important;
  /* font-weight: 550; */
  color: #666;
}

.circle1:before {
  content: "";
  height: 15px;
  width: 250px;
  position: absolute;
  right: 46px;
  top: -15px;
  background: #ff0101 !important;
  background: linear-gradient(to left, #ff0101 0%, white 100%) !important;

  /* background: linear-gradient(to left, #29bdcc 0%, white 100%); */
  z-index: -1;
  animation: shimmer 10s infinite forwards;
}

@keyframes shimmer {
  0% {
    width: 0;
  }

  5% {
    width: 250px;
  }

  100% {
    width: 250px;
  }
}

.circle1:after,
.circle8:after {
  content: "";
  position: absolute;
  left: -14px;
  top: -15px;
  width: 124.2px;
  height: 125px;
  border: 15px solid transparent;
  color: #ff0101;
  border-color: #ff0101;
  border-left-color: transparent;
  border-top-color: transparent;
  transform: rotate(-45deg);
  border-radius: 50%;
  z-index: -1;
}

/* .circle8:after {
    border-color: #b8242a;
    border-right-color: transparent;
    border-bottom-color: transparent;
    transform: rotate(-45deg);
  } */

.circle8:before {
  content: "";
  height: 15px;
  width: 250px;
  position: absolute;
  right: -202px;
  bottom: -15px;
  background: #990101;
  background: linear-gradient(to right, #ff0101 0%, #990101 100%) !important;

  /* background: linear-gradient(to right, #FF0101 0%, #990101 100%); */
  z-index: -1;
}

.circle8 {
  border-bottom-color: transparent;
}

@media screen and (max-width: 600px) {
  .wrap {
    display: block;
  }

  .ppf-content {
    margin: 0;
  }

  .col {
    margin: 10px 0;
    position: static;
  }

  .circle4:before,
  .circle4:after,
  .circle1:before,
  .circle2:after {
    display: none;
  }

  .circle1 {
    border-color: #990101;
  }

  .circle2 {
    border-color: #990101;
  }

  .circle3 {
    border-color: #990101;
  }

  .circle4 {
    border-color: #990101;
  }
}

/* the following css code is just for fun and can be removed*/
/* it applies the shimmer down the spiral using <div class="shim"> which can also be removed */
.shim {
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;
  margin-top: 0;
  width: 100%;
  height: 100%;
  animation: shim 1s linear forwards;
  transform: rotate(-90deg);
}

.shim:after {
  content: "";
  position: absolute;
  left: 50%;
  top: -15px;
  margin-left: -8px;
  width: 15px;
  height: 15px;
  background: radial-gradient(#fff, rgba(255, 255, 255, 0));
}

.shimx {
  transform: rotate(90deg);
}

.shimx {
  animation: shim2 1s linear 0.75s forwards;
}

.shim2 {
  animation-delay: 0.75s;
}

.shim3 {
  animation-delay: 1.5s;
}

.shim4 {
  animation-delay: 2.25s;
}

@keyframes shim {
  from {
    transform: rotate(-90deg);
  }

  to {
    transform: rotate(270deg);
  }
}

@keyframes shim2 {
  from {
    transform: rotate(90deg);
  }

  to {
    transform: rotate(-270deg);
  }
}

/* Testimonials.css */
/* Add these styles to Common.css */

/* Container styling */
.testimonials-container {
  padding: 1rem;
}

.testimonial-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  /* padding: 3rem; */
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 80%;
  margin: 0 auto;
}

.testimonial-text {
  margin-bottom: 1rem;
}

.testimonial-image img {
  width: 100%;
  max-width: 300px;
  border-radius: 1%;
  margin-bottom: 1rem;
}

.testimonial-navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.nav-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  margin: 0 0.5rem;
  cursor: pointer;
  border-radius: 4px;
}

.nav-button:hover {
  background-color: #0056b3;
}

.nav-dot {
  height: 10px;
  width: 10px;
  margin: 0 5px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
}

.nav-dot.active {
  background-color: #007bff;
}

/* Media query for smaller screens */
/* @media (max-width: 600px) {
  .testimonial-card {
    padding: 0.5rem;
  }

  .testimonial-text {
    font-size: 14px;
  }

  .testimonial-image img {
    max-width: 100px;
  }

  .nav-button {
    padding: 0.5rem;
    font-size: 14px;
  }

  .nav-dot {
    height: 8px;
    width: 8px;
  }
} */

/* instagram css */
/* Basic styling for the cards */

/* Basic styling for the cards */
.card {
  width: 25%;
}

.card-img-top {
  width: 100%;
  height: auto;
}

/* Zoom effect on hover */
.zoom-on-hover {
  transition: transform 0.3s ease;
}

.zoom-on-hover:hover {
  transform: scale(1.1);
}

/* Responsive styling for mobile view */
@media (max-width: 768px) {
  .card {
    width: 25%;
  }

  /* .d-flex {
    flex-wrap: wrap;
  } */
}

/* .................. */
.testimonials-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  border-radius: 12px;
}

.testimonial-heading {
  text-transform: uppercase;
  font-size: 42px;
  text-align: center;
  color: #b8242a;
  font-weight: 700;
  margin-bottom: 0px;
}

.testimonial-cards-container {
  overflow: hidden;
  width: 100%;
}

.testimonial-cards-slider {
  display: flex;
  transition: transform 0.5s ease;
  width: 200%;
}

.testimonial-card {
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  padding: 20px;
}

.testimonial-text {
  padding: 20px;
  flex: 1;
}

.testimonial-text p {
  margin: 0;
  font-family: "Arial, sans-serif";
  color: black;
  font-size: 1.3rem;
  /* font-weight: 550; */
}

.testimonial-text p:nth-child(2) {
  margin-top: 20px;
  font-size: 1.2em;
  font-weight: bold;
}

.testimonial-text p:nth-child(3) {
  margin-top: 5px;
  color: #b42424;
}

.stars {
  margin-top: 10px;
}

.star {
  color: gold;
  font-size: 1.5rem;
}

.testimonial-navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.nav-dot {
  height: 12px;
  width: 12px;
  margin: 0 5px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
}

.nav-dot.active {
  background-color: #717171;
}

.nav-button {
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  margin: 0 10px;
}

.nav-button:focus {
  outline: none;
}
