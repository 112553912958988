@media (max-width: 768px) {
    .video-content h2 {
      font-size: 2rem;
    }
    .video-content p {
      font-size: 1.25rem;
    }
    .video-main-hero-content h2 {
      font-size: 1.5rem;
    }
    .video-main-hero-content p {
      font-size: 1rem;
    }
  }
  
  @media (max-width: 576px) {
    .video-content h2 {
      font-size: 1.75rem;
    }
    .video-content p {
      font-size: 1rem;
    }
    .video-main-hero-content h2 {
      font-size: 1.25rem;
    }
    .video-main-hero-content p {
      font-size: 0.875rem;
    }
  }
  