.image-container {
  overflow: hidden; /* Ensure the image doesn't overflow the container */
}

.image-zoom {
  transition: transform 0.3s ease; /* Smooth transition for the zoom effect */
}

.image-zoom:hover {
  transform: scale(1.4); /* Scale the image to 1.1 times its original size */
}

.custom {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border: none;
}

.image-containersss {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  /* object-fit: cover; */
}

.image-containersss img {
  width: 100%;
  max-width: 100%;
  margin-bottom: 16px;
  transition: transform 0.3s ease;
}

.image-containersss img:hover {
  transform: scale(1.1);
}

@media (min-width: 768px) {
  .image-containersss img {
    width: calc(50% - 8px);
  }
}

@media (min-width: 992px) {
  .image-containersss img {
    width: calc(33.333% - 11px);
  }
}



/* whychoose us */


.icon-style {
  font-size: 2rem;
  color: #b8242a; /* Change this to match your theme */
  margin-bottom: 1rem;
}

.installation-process {
  height: auto;
  margin-bottom: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.installation-process h2 {
  text-transform: uppercase;
  font-size: 42px;
  text-align: center;
  color: #b8242a;
  font-weight: 700;
  margin-bottom: 40px;
}

.installation-process-step {
  display: inline-block;
  width: 100%;
  height: 160px;
  vertical-align: top;
  border-radius: 5px;
  margin: 0 10px;
  position: relative;
  border: 2px solid transparent;
  transition: all 0.3s ease-in-out;
  min-height: auto;
}

.installation-process-step:hover {
  transform: translateY(-10px);
}

.installation-process-step h3 {
  text-align: center;
  margin: 0;
  font-size: 21px;
  padding: 30px 0 8px 0;
  border-top-right-radius: 2px;
  border-top-left-radius: 2px;
  color: #fff;
}

.installation-process-step p {
  padding: 15px;
  font-size: 15px;
}

.purchase-step {
  border-color: #b8242a;
}

.purchase-step h3 {
  /* background-color: #b8242a; */
  background: linear-gradient(45deg, #ff0101, #990101) !important;
}

.call-scheduled-step {
  border-color: #b8242a;
}

.call-scheduled-step h3 {
  background: linear-gradient(45deg, #ff0101, #990101) !important;
}

.install-test-step {
  border-color: #b8242a;
}

.install-test-step h3 {
  background: linear-gradient(45deg, #ff0101, #990101) !important;
}

.finished-step {
  border-color: #b8242a;
}

.finished-step h3 {
  background: linear-gradient(45deg, #ff0101, #990101) !important;
}

.installation-process-step:before {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  width: 50px;
  height: 50px;
  border: 3px solid;
  border-radius: 50%;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 28px;
}

.purchase-step:before {
  content: "\f135";
  border-color: #b8242a;
  color: #b8242a;
}

.call-scheduled-step:before {
  content: "\f13d";
  border-color: #b8242a;
  color: #b8242a;
}

.install-test-step:before {
  content: "\f013";
  border-color: #b8242a;
  color: #b8242a;
}

.finished-step:before {
  content: "\f00c";
  border-color: #b8242a;
  color: #b8242a;
}

.installation-process-step + .installation-process-step:after {
  content: "\f0da";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  position: absolute;
  /* left: -25px;
   */
  font-size: 35px;
  top: 70px;
  color: #ff0101 !important;
  width: auto;
  object-fit: cover;
}